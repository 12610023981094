import JobsQuery from "../../components/queries/JobsQuery";
import React from "react";
import {Link} from "gatsby";
import {JOBS_PATH} from "./nav/NavigationItems";

const JobList = ({isHome}) => {
  const jobs = JobsQuery()

  return (
    <ul>
      {jobs.map((job, index) => {
        const meta = job.frontmatter
        const url = isHome ? JOBS_PATH.slice(1) + meta.slug : meta.slug
        return (
          <li key={index}>
            <Link to={url} className="p-link" title={meta.title}>
              {job.frontmatter.position}
            </Link>
            {/*<div dangerouslySetInnerHTML={{ __html: job.html }} />*/}
          </li>
        )
      })}
    </ul>
  );
};

export default JobList;
