import { Col, Container, Row } from "react-bootstrap";
import { JOBS, JOBS_TITLE } from "../../components/core/nav/NavigationItems";

import HomeShape from "../../components/core/ux/HomeShape";
import JobList from "../../components/core/JobList";
import Layout from "../../components/core/Layout";
import React from "react";

const JobsPage = () => (
  <Layout page={JOBS}>
    <HomeShape />
    <Container>
      <h2 className="text-uppercase h-muted">{JOBS}</h2>
      <h1 className="text-uppercase h bolder">{JOBS_TITLE}</h1>
      <div data-sal="slide-up" data-sal-duration="900">
        <h3 className="headline">
          Karriere bei <strong>new.space</strong>
          <div className="h-muted">
            Know-how für Immobilien. Engagement für Menschen.
          </div>
        </h3>
      </div>
      {/** scroll effect broken on mobile (content won't show) */}
      <Row>
        <Col lg={6}>
          <p lang="de" style={{ hyphens: "auto" }}>
            Dass <strong>new.space</strong> bei Investoren, Bauherren und
            Bewohnern sowie den Partnern aus allen Gewerken so ein hohes Ansehen
            genießt, liegt vor allem an unserem Team. Mit Einsatz, Können und
            Weitblick bringt jede*r Einzelne die zahlreichen anspruchsvollen
            Bauprojekte voran.
          </p>
          <p lang="de" style={{ hyphens: "auto" }}>
            Wir wissen, wie wichtig diese Fachkompetenz für{" "}
            <strong>new.space</strong> ist. Darum fördern und begeistern wir
            unsere Mitarbeiter*innen – und vermitteln ihnen den Respekt für ihre
            Arbeit. Wie gut die persönliche Chemie untereinander stimmt, spürt
            man in unserem modernen Büro mitten im Kasseler Stadtzentrum. Aber
            auch bei privaten Veranstaltungen: Gemeinsam grillen wir öfters,
            gehen zum Bowling oder besuchen zusammen Sport-Events.
          </p>
        </Col>
        <Col>
          <p lang="de" style={{ hyphens: "auto" }}>
            Das Miteinander ist freundschaftlich, offen und vertrauensvoll.
            Flache Hierarchien und kurze Entscheidungswege ermöglichen eine hohe
            Eigenverantwortung innerhalb der Projekte. Flexible
            Arbeitszeitmodelle oder Homeoffice sind dabei ebenso
            selbstverständlich wie hochwertige Fortbildungsmaßnahmen.
          </p>
          <p lang="de" style={{ hyphens: "auto" }}>
            Wer mit der eigenen Karriere in einem angenehmen Rahmen mit vielen
            Perspektiven durchstarten möchte, findet bei{" "}
            <strong>new.space</strong> das Umfeld, das Spaß macht – und die
            Projekte, die fachlich weiterbringen.
          </p>
          <p>Passen wir zusammen? Sprechen wir darüber!</p>
        </Col>
      </Row>
      <hr className="my-4" />
      <b>Aktuell suchen wir:</b>
      <JobList />
      <hr className="my-4" />
    </Container>
  </Layout>
);

export default JobsPage;
